import React from 'react';
import ReactDOM from 'react-dom';
import { LiveAgent } from '../../components/live-agent';

document.addEventListener('DOMContentLoaded', () => {
  const $layout = document.querySelector('#liveAgent');
  ReactDOM.render(
    <LiveAgent
      deploymentId={process.env.LIVE_AGENT_DEPLOYMENT_ID}
      organizationId={process.env.LIVE_AGENT_ORG_ID}
      buttonId={process.env.LIVE_AGENT_CRUISIN_BUTTON_ID}
      endpoint={process.env.LIVE_AGENT_URL}
      scriptSrc={process.env.LIVE_AGENT_SCRIPT_SRC}
    />,
    $layout
  );
});
